/* src/ProductPage.css */

.product-card {
    transition: transform 0.2s;
  }
  
  .product-card:hover {
    transform: scale(1.05);
  }
  
  .product-image {
    cursor: pointer;
  }
  
  .price-text {
    color: green;
    font-weight: bold;
  }
  
  .search-bar {
    margin-bottom: 20px;
  }
  