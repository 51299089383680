/* Basic Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  width: 100%;
  height: 100%;
}

/* Hero Section */
.hero-section {
  background: url('https://img.freepik.com/premium-photo/businessman-gesture-protecting-virtual-blue-dartboard-with-arrow-business-achievement-objective-target-concept_50039-2824.jpg?size=626&ext=jpg&ga=GA1.1.328031711.1718291591&semt=ais_user') no-repeat center center/cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  padding: 0 20px;
}

.hero-section .hero-content {
  max-width: 600px;
}

.hero-section h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.hero-section p {
  font-size: 1.5rem;
  margin-bottom: 30px;
}

/* Navigation */
.navigation {
  background: rgba(0, 0, 0, 0.8);
  padding: 10px 0;
  width: 100%;
  top: 0;
  z-index: 1000;
  margin-top: 60px; /* You can remove this margin-top */
}


.navigation nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
}

.navigation nav ul li {
  margin: 0 15px;
}

.navigation nav ul li a {
  color: #fff;
  text-decoration: none;
  padding: 10px 15px;
  font-size: 1rem;
}

.navigation nav ul li a:hover {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}

/* Vision Section */
.vision-section {
  padding: 60px 20px;
  background: #f9f9f9;
  text-align: center;
  padding-top: 140px; /* Increased padding-top to create more space below the nav */
}

.vision-section .section-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.vision-section p {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

/* Why Choose Us Section */
.why-choose-us-section {
  padding: 60px 20px;
  background: #ffffff;
  padding-top: 140px; /* Increased padding-top to create more space below the nav */
}

.why-choose-us-section .section-title {
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.why-choose-us-section .feature {
  padding: 20px;
  background: #f1f1f1;
  border-radius: 10px;
  margin-bottom: 20px;
}

.why-choose-us-section .feature h5 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.why-choose-us-section .feature p {
  font-size: 1rem;
}

/* Testimonials Section */
.testimonials-section {
  padding: 60px 20px;
  background: #e9ecef;
  
  color: #333;
  
  padding-top: 140px; /* Increased padding-top to create more space below the nav */
}

.testimonials-section .section-title {
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.testimonials-section .carousel-item p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.testimonials-section .carousel-item h5 {
  font-size: 1.3rem;
  font-weight: bold;
}

/* Parallax Effect */
.parallax {
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

/* Media Queries */
@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2rem;
  }

  .hero-section p {
    font-size: 1rem;
  }

  .vision-section .section-title,
  .why-choose-us-section .section-title,
  .testimonials-section .section-title {
    font-size: 2rem;
  }

  .navigation nav ul {
    flex-direction: column;
  }

  .navigation nav ul li {
    margin: 5px 0;
  }
}
