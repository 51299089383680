/* privacypolicy.css */

/* Ensure the container takes up full height and centers content */
.privacy-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Start aligning from the top */
    min-height: 100vh; /* Ensure full viewport height */
    padding: 20px; /* Add some padding around */
    box-sizing: border-box; /* Include padding in height calculation */
  }
  
  /* Center the content and add some styling */
  .content-container {
    max-width: 800px; /* Limit the width for readability */
    margin: 0 auto; /* Center horizontally */
    text-align: left; /* Align text to left for better readability */
  }
  
  h1 {
    font-size: 2em; /* Adjust heading size */
    margin-bottom: 20px; /* Space below heading */
  }
  
  p {
    line-height: 1.6; /* Improve readability */
    margin-bottom: 20px; /* Space between paragraphs */
  }
  
  strong {
    font-weight: bold; /* Ensure bold text stands out */
  }
  
  footer {
    margin-top: 40px; /* Space above footer */
    width: 100%; /* Ensure footer takes full width */
    text-align: center; /* Center the text in the footer */
  }
  