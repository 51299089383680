.cart-container {
  padding: 20px;
}

.cart-container h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #333;
}

.cart-items {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cart-item {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
}

.cart-item-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 16px;
}

.cart-item-details {
  flex-grow: 1;
}

.cart-item-details h3 {
  margin-top: 0;
  font-size: 16px;
  color: #333;
}

.cart-item-details p {
  margin-bottom: 8px;
  color: #666;
  font-size: 14px;
}

.cart-item-details button {
  margin-top: 8px;
  background-color: #e53935;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cart-item-details button:hover {
  background-color: #c62828;
}

.cart-container button.buy-now {
  margin-top: 16px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cart-container button.buy-now:hover {
  background-color: #388e3c;
}

.cart-container p.empty-cart {
  font-size: 16px;
  color: #666;
  text-align: center;
}

@media (max-width: 480px) {
  .cart-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .cart-item-image {
    width: 100%;
    height: auto;
    max-height: 200px;
    margin-right: 0;
    margin-bottom: 16px;
  }

  .cart-item-details button {
    margin-top: 12px;
  }
}
