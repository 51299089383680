/* Returnpolicy.css */

.return-policy-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .return-content {
    background-color: #f9f9f9; /* Light grey background for contrast */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  }
  
  .return-content h1 {
    text-align: center;
  }
  
  .return-content p {
    line-height: 1.6; /* Improve readability */
  }
  