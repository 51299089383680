/* General Styles */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5;
  color: #333;
}

/* Meeting Scheduler */
.meeting-scheduler {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Meeting Summary */
.meeting-summary {
  text-align: center;
  padding: 2rem;
  border-radius: 8px;
  background-color: #e0f7fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.meeting-summary img {
  max-width: 100px;
  margin-bottom: 1rem;
}

.meeting-summary h2 {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  color: #00838f;
}

.meeting-summary p {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #4a4a4a;
}

.meeting-link {
  color: #00838f;
  font-weight: bold;
}

/* Booking Section */
.booking-section {
  padding: 2rem;
  border-radius: 8px;
  background-color: #f8f8ff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.booking-section h2 {
  font-size: 1.6rem;
  margin-bottom: 1rem;
  color: #4b0082;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }
}

.form-group {
  flex: 1;
  margin-right: 1rem;
}

@media (max-width: 768px) {
  .form-group {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

.form-group label {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #4a4a4a;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #333;
}

.schedule-button {
  display: block;
  width: 100%;
  padding: 0.8rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  background-color: #4b0082;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.schedule-button:hover {
  background-color: #3a0061;
}

/* Meeting Details */
.meeting-details {
  margin-top: 2rem;
  padding: 2rem;
  border-radius: 8px;
  background-color: #e0f7fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.meeting-details h3 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  color: #00838f;
}

.meeting-details p {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #4a4a4a;
}

.meeting-details a {
  color: #00838f;
  text-decoration: none;
}

.meeting-details a:hover {
  text-decoration: underline;
}

.delete-button {
  display: block;
  width: 100%;
  padding: 0.8rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  background-color: #e53935;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #c62828;
}

/* Error and Success Messages */
.error-message {
  color: #e53935;
  font-weight: bold;
  margin-bottom: 1rem;
}

.success-message {
  color: #4caf50;
  font-weight: bold;
  margin-bottom: 1rem;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .meeting-scheduler {
    margin: 1rem;
    padding: 1rem;
  }

  .meeting-summary,
  .booking-section,
  .meeting-details {
    padding: 1rem;
  }
}
