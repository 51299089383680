/* TermsConditions.css */

.terms-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f4; /* Light grey background for the container */
  }
  
  .terms-content {
    background-color: #ffffff; /* White background for the content area */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Shadow for a subtle 3D effect */
  }
  
  .terms-content h1 {
    text-align: center;
    color: #333; /* Dark color for the heading */
  }
  
  .terms-content p {
    line-height: 1.6; /* Improve readability */
    margin: 0;
    color: #555; /* Slightly lighter color for the text */
  }
  
  .terms-content ul {
    margin-left: 20px; /* Indent list items */
  }
  
  .terms-content li {
    margin-bottom: 10px; /* Space between list items */
  }
  