
.glow-button {
  background-color: #28a745; 
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1.2em;
  border-radius: 5px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.glow-button:hover {
  background-color: #218838; 
  box-shadow: 0 0 10px #0f0f0f;
}

