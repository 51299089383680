.navbar {
  background-color: #f5f5dc !important;
  transition: top 0.3s;
  z-index: 1000;
}

.navbar.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-brand {
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex: 0 0 auto;
}

.navbar-brand img.logo {
  max-width: 350px;
  width: 100%;
  height: auto;
  margin-right: 10px;
  transition: width 0.3s ease-in-out;
}

.navbar-brand .brand-text {
  font-size: 1.5rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 220px);
  transition: font-size 0.3s ease-in-out;
}

.navbar-toggler {
  border-color: #000000 !important;
  position: absolute;
  right: 15px;
  margin-left: auto;
}

.navbar-toggler-icon {
  background-image: none !important;
  border: none;
  width: 24px;
  height: 24px;
  position: relative;
}

.navbar-toggler-icon::before,
.navbar-toggler-icon::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000000;
  position: absolute;
  left: 0;
  transition: all 0.3s;
}

.navbar-toggler-icon::before {
  top: 6px;
}

.navbar-toggler-icon::after {
  bottom: 6px;
}

.navbar-toggler-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000000;
  transform: translateY(-50%);
}

.time-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 1rem;
}

.time-display {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.65rem;
  font-weight: bold;
  white-space: nowrap;
}

.flag-icon {
  width: 16px;
  height: auto;
}

.time-text {
  margin: 0 3px;
}

.navbar-nav {
  margin-left: auto;
}

/*.nav-link, .nav-dropdown-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 0.6rem !important;
  font-size: 0.85rem;
}*/

/* Services dropdown styles */
.navbar-nav .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  min-width: 200px;
  padding: 0.5rem 0;
  margin: 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
}

.navbar-nav .dropdown:hover .dropdown-menu {
  display: block;
}

.navbar-nav .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 1;
}

.navbar-nav .dropdown-item:hover, .navbar-nav .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

@media (max-width: 1200px) {
  .navbar-brand img.logo {
    max-width: 280px;
  }
  
  .nav-link, .nav-dropdown-link {
    padding: 0.5rem 0.4rem !important;
    font-size: 0.9rem;
  }
  
  .time-display {
    font-size: 0.6rem;
  }
}

@media (max-width: 992px) {
  .navbar-brand img.logo {
    max-width: 220px;
  }
  
  .nav-link, .nav-dropdown-link {
    padding: 0.5rem 0.35rem !important;
    font-size: 0.75rem;
  }
  
  .time-display {
    font-size: 0.55rem;
  }
  .time-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
  }

  .navbar-nav {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .navbar-brand {
    justify-content: flex-start;
    padding: 0 10px;
    flex-wrap: wrap;
  }

  .navbar-brand img.logo {
    max-width: 180px;
  }

  .navbar-brand .brand-text {
    font-size: 1.2rem;
    max-width: none;
    text-align: left;
    white-space: nowrap;
    order: 2;
  }

  .navbar-brand .brand-text-container {
    display: flex;
    align-items: center;
    order: 3;
    font-size: 1.2rem;
    margin-left: 10px;
  }

  .navbar-brand .brand-text-black {
    color: #000000;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .navbar-brand .brand-text-blue {
    color: #0000ff;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .navbar-toggler {
    right: 10px;
    order: 4;
    padding: 0.25rem 0.5rem;
  }

  .navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #f5f5dc;
    padding: 1rem;
    z-index: 1000;
  }
  
  .nav-link, .nav-dropdown-link {
    padding: 0.5rem !important;
    font-size: 1rem;
  }

  .time-display {
    display: none;
  }

  .navbar-nav .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
}

@media (max-width: 480px) {
  .navbar-brand img.logo {
    width: 50px;
  }

  .navbar-brand .brand-text {
    font-size: 1rem;
  }

  .navbar-brand .brand-text-container {
    font-size: 1rem;
  }
}

.navbar.sticky {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-brand .logo {
  height: 40px;
  padding-left: 20px;
}

.nav-link span {
  margin-left: 5px;
}

/* Additional adjustments */
.container-fluid {
  padding-right: 5px !important;
}

.navbar-collapse {
  flex-grow: 0;
}

.navbar-nav {
  margin-right: -0.4rem;
}
/* NavBar.css */

.nav-dropdown-link:hover .dropdown-menu {
  display: block;
}

.nav-dropdown-link .dropdown-menu {
  display: none;
  margin-top: 0;
}

.nested-dropdown:hover .dropdown-menu {
  display: block;
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: 0.1rem; /* Adjust as needed */
}
