.container1 {
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 32px;
    flex: 1;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    align-content: space-between;
    justify-content: space-between;
    align-self: stretch;
    align-items: center;
    padding-top: 50px;
  }
  
  .logo {
    width: 100%;
    height: 100px;
    margin: 1px; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .logo img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  @media (max-width: 768px) {
    .container1 {
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      grid-row-gap: 24px;
      padding-top: 40px;
    }
  
    .logo {
      height: 80px;
    }
  }
  
  @media (max-width: 480px) {
    .container1 {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      grid-row-gap: 18px;
      padding-top: 30px;
    }
  
    .logo {
      height: 60px;
    }
  }
  

  