
.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 12px;
  border-radius: 50%;
  background-color: #F0F0F0;
  transition: background-color 0.3s ease;
}

.social-icon:hover {
  background-color: #e9ecef;
}

.social-icon img {
  width: 18px;
  height: 18px;
}


.map-container {
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 24px;
}

.map-wrapper {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; 
}

.map-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}




