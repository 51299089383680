/* RefundandCancellationPolicy.css */

.refund-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f4; /* Light grey background for the container */
  }
  
  .refund-content {
    background-color: #ffffff; /* White background for the content area */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Shadow for a subtle 3D effect */
  }
  
  .refund-content h1 {
    text-align: center;
    color: #333; /* Dark color for the heading */
  }
  
  .refund-content p {
    line-height: 1.6; /* Improve readability */
    margin: 0;
    color: #555; /* Slightly lighter color for the text */
  }
  