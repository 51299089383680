/* ShippingPolicy.css */
.shipping-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f4f4f4; /* Background color for the entire page */
  }
  
  .text-container {
    text-align: left; /* Align text to the left for readability */
    padding: 20px;
    background-color: #fff; /* Background color for the text container */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for depth */
    max-width: 800px; /* Maximum width of the container */
    width: 90%; /* Responsive width */
    margin-bottom: 40px; /* Space below the text container */
  }
  
  .additional-info {
    text-align: left;
    padding: 20px;
    background-color: #fff; /* Background color for the additional info */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for depth */
    max-width: 800px; /* Maximum width of the container */
    width: 90%; /* Responsive width */
  }
  
  .additional-info h2, .additional-info h3 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .additional-info p, .additional-info ul {
    margin: 10px 0;
  }
  
  .additional-info ul {
    list-style-type: none;
    padding: 0;
  }
  
  .additional-info li {
    margin: 5px 0;
  }
  
  .additional-info a {
    color: #007BFF;
    text-decoration: none;
  }
  
  .additional-info a:hover {
    text-decoration: underline;
  }
  
  .additional-info strong {
    font-weight: bold;
  }
  
  