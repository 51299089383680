/* Careers.css */

/* Container for the careers page */
.careers-page {
  background-color: #f8f9fa;
  padding: 20px;
}

/* Styling for the job cards grid */
.jobs-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Adjust the gap between cards as needed */
}

/* Individual job card styling */
.job-card {
  flex: 1 1 calc(33.333% - 20px); /* Adjust the percentage based on how many columns you want */
  box-sizing: border-box;
}

/* Card styling */
.card {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Ensure card title is bold */
.card-title {
  font-size: 1.25rem;
  font-weight: bold;
}

/* Ensure card text has appropriate margin */
.card-text {
  margin-bottom: 1rem;
}

/* Align the button at the bottom of the card */
.btn-primary {
  margin-top: auto;
}

/* Title styling */
.careers-title {
  color: black; /* Change the color to black */
  text-align: center;
  margin-bottom: 2rem; /* Add some margin below the title */
  font-size: 2rem; /* Adjust font size as needed */
  font-weight: bold; /* Make the title bold */
  
}
.urgent-image {
  width: 30px; /* Set desired width */
  height: auto; /* Maintain aspect ratio */
  margin-left: 10px; /* Add space between text and image */
  align-self: center; /* Center the image vertically */
}
